<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="white" dark flat>
            <v-img
              :max-width="86"
              :max-height="56"
              src="../assets/logo.png"
            ></v-img>
            <v-spacer></v-spacer>
            <v-toolbar-title class="black--text">BCRM</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-flex>
                <v-alert v-if="isLoginError" type="error" dismissible>
                  {{ loginErrorMessage }}
                </v-alert>
              </v-flex>
              <v-text-field
                id="username"
                v-model="username"
                label="Username"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                color="secondary"
                :rules="usernameRules"
                required
                @keyup.enter="login"
              />

              <v-text-field
                id="password"
                v-model="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                color="secondary"
                :rules="passwordRules"
                required
                @keyup.enter="login"
              />
            </v-form>
            <v-row>
              <v-col>
                <v-btn
                  class="black--text"
                  color="primary"
                  large
                  block
                  :loading="isLoginLoading"
                  @click="login"
                >
                  Login
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col dense cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
                <v-btn
                  outlined
                  color="#1877F2"
                  small
                  block
                  @click="loginFacebook"
                >
                  <v-icon left> mdi-facebook </v-icon>
                  Sign in
                </v-btn>
              </v-col>
              <v-col dense cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
                <v-btn
                  outlined
                  color="#db3236"
                  small
                  block
                  @click="loginGoogle"
                >
                  <v-icon left> mdi-google </v-icon>
                  Sign in
                </v-btn>
              </v-col>
              <v-col dense cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
                <v-btn outlined color="#00c300" small block @click="loginLine">
                  <v-icon left> fab fa-line </v-icon>
                  Sign in
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  props: {
    // source: String,
  },
  data() {
    return {
      drawer: null,
      username: "",
      password: "",
      submitted: false,
      showPassword: false,
      usernameRules: [(v) => !!v || "Username is required"],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  created() {
    this.checkErrorMessage();
  },
  computed: {
    ...mapGetters("user", {
      isLoginError: "isLoginError",
      isLoginLoading: "isLoginLoading",
      loginErrorMessage: "loginErrorMessage",
    }),
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        const { username } = this;
        const { password } = this;
        this.$store
          .dispatch("user/login", { username, password })
          .then(() => this.$router.push("/dashboard"))
          // eslint-disable-next-line no-console
          .catch((error) => console.log(error));
      }
    },
    loginFacebook() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "user/authenticate/facebook/login/start";
      // "user/authenticate/facebook/login/start";
    },
    loginGoogle() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "user/authenticate/google/login/start";
    },
    loginLine() {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL + "user/authenticate/line/login/start";
    },
    checkErrorMessage() {
      if (typeof this.$route.query.errormessage !== "undefined") {
        const errorMessage = this.$route.query.errormessage;
        this.$store.dispatch("user/loginError", errorMessage);
      }
    },
  },
};
</script>
